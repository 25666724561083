define("discourse/plugins/discourse-follow/discourse/users-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "user",
    map() {
      this.route("follow", {
        path: "/follow",
        resetNamespace: true
      }, function () {
        this.route("feed", {
          path: "/feed",
          resetNamespace: true
        });
        this.route("followers", {
          path: "/followers",
          resetNamespace: true
        });
        this.route("following", {
          path: "/following",
          resetNamespace: true
        });
      });
    }
  };
});