define("discourse/plugins/discourse-follow/discourse/widgets/following-notification-item", ["discourse/widgets/widget", "discourse/widgets/default-notification-item", "discourse/lib/url", "I18n"], function (_widget, _defaultNotificationItem, _url, _I18n) {
  "use strict";

  (0, _widget.createWidgetFrom)(_defaultNotificationItem.DefaultNotificationItem, "following-notification-item", {
    description() {
      return _I18n.default.t("notifications.following_description");
    },
    url(data) {
      return (0, _url.userPath)(data.display_username);
    }
  });
});